import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import profilePic from '../assets/chr_circle.png'
import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
        }}
      >
        <img
          src={profilePic}
          alt={`Christian Brevik`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(4),
            height: rhythm(4),
          }}
        />
        <p>
          <strong>Hi there!</strong> I'm a developer living in Trondheim. Find
          me on <a href="https://github.com/cbrevik">Github</a> or{' '}
          <a href="https://twitter.com/ChristianBrevik">Twitter</a>.
        </p>
      </div>
    )
  }
}

export default Bio
